/**
 * 转换详情页内容文本，过滤其中的 html 标签内容
 * @param {string} rawdata
 */
export default function transformProjectDetail(rawdata) {
    if (!rawdata) return '';

    let result = rawdata
        .replace(/&lt;p\s*&gt;/g, '')
        .replace(/&lt;\/p\s*&gt;/g, '\n')
        .replace(/&lt;\s*br\s*\/?&gt;/g, '');

    if (!/<[^>]+>/g.test(result)) {
        result = result.replace(/\n+\s?\n?/g, '</p><p>').replace(/<p>(<br\s*\/>)*<\/p>/g, '');
        result = '<p>' + result + '</p>';
    }

    return result;
}
