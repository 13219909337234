
<template>
    <SectionCard title="求助故事" class="project-story">
        <div class="project-description">
            <div class="main-content" :class="{active: true}">
                <div class="content-desc" v-html="detailHtmlStr"></div>
                <!-- <span class="mask" v-if="!isFold && hideProjectDesc" @click="showAll"></span> -->
            </div>

            <span v-if="false&&!isFold && hideProjectDesc" class="see-more" @click="showAll">
                查看全文 <i class="list-arrow"></i>
            </span>
        </div>

        <ProjectStoryImages />

    </SectionCard>
</template>

<script>
import { mapState } from 'vuex';
import transformProjectDetail from '../../js/transformProjectDetail';
import SectionCard from '../SectionCard/index.vue';

export default {
    name: 'ProjectStory',
    data () {
        return {
            isFold: false,
            hideProjectDesc: false,
        }
    },
    components: {
        SectionCard,
        ProjectStoryImages:() => import('./ProjectStoryImages/index'),
    },
    computed: {
        ...mapState(['introduction']),

        detailHtmlStr() {
            return transformProjectDetail(this.introduction);
        },
    },
    mounted() {
        this.getProjectDescContent();
    },
    methods: {
        showAll() {
            this.isFold = true
        },

        getProjectDescContent() {
            const descHeight = document.querySelector('.content-desc');
            if (descHeight && descHeight.offsetHeight > 94) {
                this.hideProjectDesc = true;
            }
        }
    }

}
</script>

<style lang='less' scoped>
.project-story {
   padding-bottom: 18px;
}
.project-description {
    margin: 0 auto;
    font-size: 17px;
    color: #333333;
    position: relative;
    background: #fff;

    .main-content{
        height: 100%;
        max-height: 94px;
        // display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        word-break: break-all;
        text-align: justify;
        position: relative;

        &.active {
            max-height: initial;
            -webkit-line-clamp: initial;
        }

        .mask {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 60px;
            content: "";
            background: -webkit-gradient(linear,left top,left bottom,from(hsla(0,0%,100%,0)),to(#fff));
            background: linear-gradient(180deg,hsla(0,0%,100%,0),#fff)
        }
    }
    .see-more {
        font-size: 16px;
        color: #009BFF;
        display: block;
        background: #fff;
        text-align: center;
        padding: 5px 0 0;

        .list-arrow {
            display: inline-block;
            width: 8px;
            height: 8px;
            position: relative;
            bottom: 2px;
            border-bottom: 1px solid #009BFF;
            border-right: 1px solid #009BFF;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            margin-left: 2px;
        }
    }

    .content-desc {
        /deep/ p + p {
            margin-top: 16px;
        }
    }
}
</style>
